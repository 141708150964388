import React, { useContext } from "react"

import AppContext from "../app-context"
import ContentfulFooterBlock from "../components/contentful-footer-block"
import ContentfulFullscreenPictureBlock from "../components/contentful-fullscreen-picture-block"
import ContentfulHeadingBlock from "../components/contentful-heading-block"
import ContentfulIntroductionBlock from "../components/contentful-introduction-block"
import ContentfulRichTextBlock from "../components/contentful-rich-text-block"
import ContentfulSliderBlock from "../components/contentful-slider-block"
import ContentfulSuggestionsBlock from "../components/contentful-suggestions-block"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { Box } from "theme-ui"

const LandingPagePage = ({
  pageContext,
}) => {
  const landingPage = pageContext.node
  const { setIsContactModalVisible } = useContext(AppContext)

  return (
    <>
      <SEO
        description={landingPage.metaDescription?.metaDescription}
        title={landingPage.metaTitle}
      />
      <Layout
        contactForms={landingPage.hubspotForms}
        customText={landingPage.menuDescription?.menuDescription}
        headerLabel={landingPage.headerLabel}
      >
        <Box sx={{ display: ["none", "block"] }}>
          <ContentfulFullscreenPictureBlock picture={landingPage.picture1} />
        </Box>
        <Box sx={{ display: ["block", "none"] }}>
          <ContentfulFullscreenPictureBlock
            picture={landingPage.picture1Mobile}
          />
        </Box>
        <ContentfulIntroductionBlock
          action={landingPage.introductionAction}
          body={landingPage.introductionBody?.introductionBody}
          bodyTags={landingPage.introductionBodyTags}
          description={
            landingPage.introductionDescription?.introductionDescription
          }
          headline={landingPage.introductionHeadline?.introductionHeadline}
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          onAction={() => {
            setIsContactModalVisible(true)
          }}
          subheadline={
            landingPage.introductionSubheadline?.introductionSubheadline
          }
          tagsHeading="Capabilities"
          tags={landingPage.introductionTags}
        />
        <Box sx={{ display: ["none", "block"] }}>
          <ContentfulSliderBlock
            heading={landingPage.carouselHeading}
            slides={landingPage.carousel}
            ratioed={false}
          />
        </Box>
        <Box sx={{ display: ["block", "none"] }}>
          <ContentfulSliderBlock
            heading={landingPage.carouselHeading}
            slides={landingPage.carouselMobile}
            ratioed={false}
          />
        </Box>

        <ContentfulRichTextBlock richText={landingPage.richText1} />

        <Box sx={{ display: ['none', 'block'] }}>
          <ContentfulFullscreenPictureBlock picture={landingPage.picture2} />
        </Box>

        <Box sx={{ display: ["block", "none"] }}>
          <ContentfulFullscreenPictureBlock
            picture={landingPage.picture2Mobile}
          />
        </Box>

        <ContentfulHeadingBlock
          subtext={landingPage.feedbackAuthor}
          text={landingPage.feedbackBody?.feedbackBody}
        />

        {landingPage.suggestedProjects &&
          landingPage.suggestedProjects.length > 0 && (
            <ContentfulSuggestionsBlock
              projects={landingPage.suggestedProjects}
              title={landingPage.suggestedTitle}
            />
          )}

        <ContentfulFooterBlock
          address={landingPage.footer?.address?.address}
          backgroundColor={landingPage.footer?.backgroundColor}
          copyright={landingPage.footer?.copyright?.copyright}
          socialLinksTitle={landingPage.footer?.socialLinksTitle}
          blogTitle={landingPage.footer?.blogTitle}
          newsletterJoinTitle={landingPage.footer?.newsletterJoinTitle}
          facebookLink={landingPage.footer?.facebookLink}
          form={landingPage.footer?.form}
          gif={landingPage.footer?.gIf}
          gifDescription={landingPage.footer?.gifDescription}
          instagramLink={landingPage.footer?.instagramLink}
          linkedInLink={landingPage.footer?.linkedInLink}
        />
      </Layout>
    </>
  )
}

export default LandingPagePage
